<template>
  <v-container class="not-found">
    <not-found />
  </v-container>
</template>
<script>
import NotFound from "@/components/category/not-found.vue";

export default {
  name: "NotFound",
  components: [NotFound]
};
</script>
