<template>
  <div style="height: 50vh;" class="text-vertical-center text-center">
    <v-img
      alt="not found"
      width="363px"
      height="321px"
      style="margin: 0 auto;"
      src="/img_layout/error/not-found.png"
    />
    <v-btn
      color="primary"
      depressed
      style="text-decoration: none"
      class="font-weight-bold mt-5"
      :to="{ name: 'Home' }"
    >
      TORNA ALLA HOMEPAGE
    </v-btn>
    <h1 class="font-weight-bold">Pagina non trovata!</h1>
    <h3>Ci dispiace. La pagina che hai cercato non è stata trovata.</h3>
    <h4>Ti invitiamo a effettuare una nuova ricerca.</h4>
  </div>
</template>
<script>
export default {
  name: "NotFoundComponent"
};
</script>
<style lang="scss">
.not-found {
  background-color: var(--v-grey-lighten2);
  max-width: 100% !important;
  .text-vertical-center {
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
  .text-vertical-center h1 {
    margin: 0;
    padding: 0;
    color: $primary;
    margin: 0;
    padding: 18px 0;
    font: 900 35px;
  }
  .text-vertical-center h3 {
    margin: 0;
    padding: 5px 0;
    font: 800 22px;
  }
  .text-vertical-center h4 {
    margin: 0;
    padding: 0 0 30px 0;
    font: 400 20px;
  }
}
</style>
